<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-mainbg"></div>
    <div class="container p-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0"
          >
            <div class="text-center text-muted mb-4">
              <h5><strong>Recover your password</strong></h5>

              <p v-if="!requestSent" class="text-center">
                You will receive an email with the steps required to reset your
                password
              </p>
            </div>

            <form
              v-if="!requestSent"
              role="form"
              @submit.prevent="forgotPassword"
            >
              <base-input
                alternative
                v-model="email"
                type="email"
                class="mb-3"
                placeholder="Email"
                prepend-icon="ni ni-email-83"
                :error="errorFor('email')"
              >
              </base-input>

              <div class="text-center">
                <base-button
                  type="primary"
                  native-type="submit"
                  class="my-4"
                  :disabled="sendingRequest"
                  >Send</base-button
                >
              </div>
            </form>

            <p class="text-center" v-else>
              We have sent you an email with the steps required to reset your
              password
            </p>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import handlesApiErrors from "@/mixins/handlesApiErrors";

export default {
  name: "forgot-password",

  mixins: [handlesApiErrors],

  data: function() {
    return {
      email: "",

      requestSent: false,
      sendingRequest: false,
    };
  },

  methods: {
    async forgotPassword() {
      try {
        this.clearApiErrors();
        this.sendingRequest = true;

        await this.axios.post("auth/forgot-password", {
          email: this.email,
        });

        this.requestSent = true;
      } catch (e) {
        this.handleApiErrors(e);
      }

      this.sendingRequest = false;
    },
  },
};
</script>
